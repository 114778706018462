.image-container {
    height: 86vh;
    width: auto;
}

.image-slide {
    height: 86vh;
    width: auto;
}

.image-slide>div img {
    height: 86vh;
    width: 100%;
    object-fit: cover;
  }

.spacer {
    height: 4vh;
}